import styled from "../_snowpack/pkg/@emotion/styled.js";
import React, {useState} from "../_snowpack/pkg/react.js";
import Logo from "./logo.js";
import {Cart, ChevronDown, Menu, Profile, X} from "./icons.js";
import {breakpoints, colors, fontFamily, media} from "./theme.js";
import {GridContainer} from "./grid.js";
import {Text} from "./typo.js";
import {t} from "../common/i18n.js";
import {getLinkUrl, stopForward} from "../common/utils.js";
import {useOnClickOutside, useRect} from "../common/hooks.js";
import {getUrl} from "../common/universal.js";
import {Routes} from "../typings/routes.js";
import Banner from "./banner.js";
import {useCartStore} from "../stores/cart.js";
export default function Header(props) {
  const {
    menus,
    banners,
    sessionUser,
    locales = [],
    languageSwitch,
    locale,
    url,
    ...rest
  } = props;
  const {header} = menus;
  const {cart} = useCartStore();
  const totalCount = cart?.items_count;
  const [menuActive, setMenuActive] = useState(false);
  const onMenuClick = () => setMenuActive(!menuActive);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, banners && banners.map((banner) => /* @__PURE__ */ React.createElement(Banner, {
    key: banner.id,
    ...banner
  })), /* @__PURE__ */ React.createElement(Container, {
    active: menuActive,
    ...rest
  }, /* @__PURE__ */ React.createElement(Inner, null, /* @__PURE__ */ React.createElement(MobileContainer, null, /* @__PURE__ */ React.createElement(LogoContainer, null), /* @__PURE__ */ React.createElement(NavButton, {
    onClick: onMenuClick
  }, menuActive ? /* @__PURE__ */ React.createElement(X, null) : /* @__PURE__ */ React.createElement(Menu, null))), /* @__PURE__ */ React.createElement(Navs, {
    active: menuActive
  }, /* @__PURE__ */ React.createElement(MenuNav, null, header && /* @__PURE__ */ React.createElement(HeaderMenu, {
    ...header
  })), /* @__PURE__ */ React.createElement(CartNav, {
    count: totalCount
  }), /* @__PURE__ */ React.createElement(ProfileNav, {
    user: sessionUser
  }), locales.length > 1 && /* @__PURE__ */ React.createElement(LocaleNav, null, locales.map((l) => /* @__PURE__ */ React.createElement(LocaleLink, {
    key: l,
    active: l === locale,
    href: languageSwitch[l]
  }, l)))))));
}
function HeaderMenu(props) {
  const {groups} = props;
  if (!groups)
    return null;
  return /* @__PURE__ */ React.createElement(HeaderMenuContainer, null, groups.map((g) => /* @__PURE__ */ React.createElement(MenuGroup, {
    key: g._uid,
    ...g
  })));
}
function CartNav(props) {
  const {count = 0} = props;
  const cartUrl = getUrl(Routes.cartRenderCustomerCart);
  return /* @__PURE__ */ React.createElement(IconLink, {
    href: cartUrl
  }, /* @__PURE__ */ React.createElement(CartIconContainer, null, /* @__PURE__ */ React.createElement(Cart, null), count !== 0 && /* @__PURE__ */ React.createElement(CartItemsCount, null, count)), /* @__PURE__ */ React.createElement("span", null, t("Warenkorb")));
}
function ProfileNav(props) {
  const {user} = props;
  const profileUrl = getUrl(Routes.profile);
  const logoutUrl = getUrl(Routes.logout);
  if (!user) {
    return /* @__PURE__ */ React.createElement(ProfilNavContainer, null, /* @__PURE__ */ React.createElement(Group, {
      oneCol: true,
      headline: /* @__PURE__ */ React.createElement(ProfileLink, {
        href: profileUrl
      }, /* @__PURE__ */ React.createElement(Profile, null), /* @__PURE__ */ React.createElement("span", null, t("Profil")))
    }));
  }
  return /* @__PURE__ */ React.createElement(ProfilNavContainer, null, /* @__PURE__ */ React.createElement(Group, {
    oneCol: true,
    headline: /* @__PURE__ */ React.createElement(ProfileLink, null, /* @__PURE__ */ React.createElement(Profile, null), /* @__PURE__ */ React.createElement("span", null, t("Profil")))
  }, /* @__PURE__ */ React.createElement(GroupDropdownLink, {
    href: profileUrl
  }, /* @__PURE__ */ React.createElement(GroupDropdownText, null, t("Kundenbereich"))), /* @__PURE__ */ React.createElement(GroupDropdownLink, {
    href: logoutUrl
  }, /* @__PURE__ */ React.createElement(GroupDropdownText, null, t("Abmelden")))));
}
function notOnPhone(fn) {
  return function() {
    if (window.innerWidth < breakpoints.desktop)
      return;
    return fn();
  };
}
function MenuGroup(props) {
  const {component, text} = props;
  const isLink = component === "menu_link";
  if (isLink) {
    const {link} = props;
    return /* @__PURE__ */ React.createElement(Group, {
      headline: text,
      as: "a",
      href: getLinkUrl(link)
    });
  }
  const {links} = props;
  return /* @__PURE__ */ React.createElement(Group, {
    headline: text
  }, links.map((l) => {
    const href = getLinkUrl(l.link);
    return /* @__PURE__ */ React.createElement(GroupDropdownLink, {
      key: l._uid,
      href,
      hovercolor: l.hover_color
    }, l.icon && /* @__PURE__ */ React.createElement(GroupDropdownIcon, {
      src: l.icon.filename,
      loading: "lazy"
    }), /* @__PURE__ */ React.createElement(GroupDropdownText, null, l.text));
  }));
}
function Group(props) {
  const {headline, children, oneCol, ...rest} = props;
  const [active, setActive] = useState(false);
  if (!children) {
    return /* @__PURE__ */ React.createElement(GroupContainer, {
      active,
      ...rest
    }, /* @__PURE__ */ React.createElement(GroupText, null, headline));
  }
  const ref = useOnClickOutside(() => setActive(false));
  const toggleActive = () => setActive(!active);
  const mouseOver = notOnPhone(() => setActive(true));
  const mouseLeave = notOnPhone(() => setActive(false));
  return /* @__PURE__ */ React.createElement(GroupContainer, {
    ref,
    onClick: toggleActive,
    onMouseOver: mouseOver,
    onMouseLeave: mouseLeave,
    active,
    ...rest
  }, /* @__PURE__ */ React.createElement(GroupText, null, headline), /* @__PURE__ */ React.createElement(GroupChevron, {
    active
  }), /* @__PURE__ */ React.createElement(GroupDropdown, {
    active,
    oneCol
  }, children));
}
function GroupDropdown(props) {
  const {children, ...rest} = props;
  const {ref, rect} = useRect((el) => {
    el.style.height = "auto";
    return () => {
      el.style.height = "";
    };
  });
  const height = rect ? rect.height + 48 : 500;
  return /* @__PURE__ */ React.createElement(GroupDropdownContainer, {
    ref,
    style: {"--height": `${height}px`},
    ...rest
  }, children);
}
const Container = styled.div`
  height: 80px;
  z-index: 2000;
  isolation: isolate;

  ${media.mobile} {
    height: 64px;
    background: ${colors.w};
  }

  ${({active}) => active && `
      ${media.mobile} {
        height: auto;

        & + * {
          height: 0px;
          overflow: hidden;
        }
      }
    `}
`;
const MobileContainer = styled.div`
  ${media.mobile} {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 64px;
    background: ${colors.w};
    z-index: 3;
  }
`;
const LogoContainer = styled(Logo)`
  ${media.mobile} {
    width: 94px;
    z-index: 3;
  }
`;
const Inner = styled(GridContainer)`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;

  ${media.mobile} {
    display: block;
    // &:after {
    //   content: "";
    //   display: block;
    //   background: ${colors.w};
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   z-index: 2;
    // }
  }
`;
const NavButton = styled.a`
  display: none;
  margin-left: auto;
  cursor: pointer;

  ${media.mobile} {
    display: block;
    z-index: 3;
  }
`;
const Navs = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  ${media.mobile} {
    flex-direction: column;
    align-items: initial;
    min-height: calc(100vh - 64px);
    transform: translateY(-100%);
    z-index: 1;
    background: ${colors.w};
    transition: transform 0.3s;
    margin: 0 -16px;

    ${({active}) => active && `
        transform: translateY(0);
      `};
  }
`;
const MenuNav = styled.div`
  margin: auto;

  ${media.mobile} {
    margin: 0;
    order: 2;
  }
`;
const IconLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  line-height: 0;
  font-size: 0;
  font-family: ${fontFamily.secondary};
  margin-right: 12px;
  margin-left: 0;
  text-decoration: none;

  ${media.mobile} {
    margin-right: 0;
    font-size: 16px;
    color: ${colors.b};
    font-weight: 700;
    padding: 22px;
    border-top: 1px solid ${colors.b6};
    border-bottom: 1px solid ${colors.b6};
    order: 3;

    span {
      margin-left: 10px;
    }
  }
`;
const ProfileLink = styled(IconLink)`
  margin-left: 0;
  margin-right: 0;
  ${media.mobile} {
    padding: 0;
    border-top: none;
    border-bottom: none;
  }
`;
const CartIconContainer = styled.div`
  position: relative;
`;
const CartItemsCount = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 12px;
  font-family: ${fontFamily.secondary};
  color: ${colors.c};
  border: 1px solid ${colors.c};
  background: ${colors.bg};
`;
const LocaleNav = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;

  ${media.mobile} {
    margin: 0;
    order: 1;
    border-top: 1px solid ${colors.b6};
    padding: 22px;
  }
`;
const LocaleLink = styled.a`
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  font-family: ${fontFamily.secondary};
  color: ${colors.b4};

  & ~ a {
    margin-left: 12px;
  }

  ${({active}) => active && `
      color: ${colors.b};
    `}

  ${media.mobile} {
    padding-right: 16px;

    & + a {
      margin-left: 0;
      padding-left: 16px;
      border-left: 1px solid ${colors.b5};
    }
  }
`;
const HeaderMenuContainer = styled.div`
  display: flex;

  ${media.mobile} {
    display: block;
  }
`;
const GroupContainer = styled.div`
  display: block;
  position: relative;

  text-decoration: none;
  color: ${colors.b2};
  cursor: pointer;
  font-weight: 700;

  &:hover {
    color: ${colors.b};
  }

  + div,
  + a {
    margin-left: min(5vw, 60px);
  }

  ${({active}) => active && `
      color: ${colors.b};
    `}

  ${media.mobile} {
    padding: 22px;
    color: ${colors.b};
    border-top: 1px solid ${colors.b6};

    + div,
    + a {
      margin-left: 0;
    }
  }
`;
const GroupText = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  font-family: ${fontFamily.secondary};
`;
const GroupChevron = styled(ChevronDown, stopForward("active"))`
  display: none;
  position: absolute;
  top: 24px;
  right: 26px;
  transition: transform 0.3s;

  ${media.mobile} {
    display: block;
  }

  ${({active}) => active && `
      transform: rotate(-180deg);
    `}
`;
const GroupDropdownContainer = styled.div`
  position: absolute;
  left: 50%;
  top: calc(100% + 10px);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 22px;
  background: ${colors.w};
  padding: 44px;
  opacity: 0;
  pointer-events: none;
  transform: translateX(-50%);
  border-radius: 4px;
  box-shadow: 0px 24px 80px 0px rgba(49, 70, 89, 0.17);

  ${({oneCol}) => oneCol && `grid-template-columns: 1fr;`}

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    height: 10px;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: calc(50% - 10px);
    top: -8px;
    width: 20px;
    height: 20px;
    background: ${colors.w};
    transform: rotate(45deg);
  }

  ${media.mobile} {
    position: relative;
    left: 0;
    top: 0;
    grid-template-columns: 1fr;
    row-gap: 24px;
    opacity: 1;
    pointer-events: auto;
    padding: 0 24px;
    background: ${colors.b6};
    box-shadow: none;
    transform: none;
    border-radius: 0;
    margin-top: 22px;
    margin-left: -22px;
    margin-right: -22px;
    margin-bottom: -22px;
    height: 0;
    overflow: hidden;
    transition: height 0.6s, padding 0.3s;

    &:before,
    &:after {
      display: none;
    }
  }

  ${({active}) => active && `
      opacity: 1;
      pointer-events: auto;

      ${media.mobile} {
        padding-top: 24px;
        padding-bottom: 24px;
        height: var(--height);
      }
    `}
`;
const GroupDropdownLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${colors.b};
  white-space: nowrap;
  transition: color 0.15s ease;

  &:hover {
    color: ${colors.c};
  }

  ${({hovercolor}) => {
  return hovercolor && `
        &:hover {
          color: ${hovercolor};
        }
      `;
}}
`;
const GroupDropdownText = styled(Text)`
  font-family: ${fontFamily.secondary};
  font-size: 16px;
  font-weight: 700;
  transition: transform 0.2s ease;
`;
const GroupDropdownIcon = styled.img`
  display: block;
  width: 32px;
  height: 32px;
  object-fit: cover;
  margin-right: 16px;
`;
const ProfilNavContainer = styled.div`
  position: relative;
  margin-left: 0;
  margin-right: 0;
  ${media.mobile} {
    margin-left: 0;
    margin-right: 0;
    order: 3;
  }
`;
